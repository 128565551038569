nav {
   width: 300px;

   .nav {
      overflow: auto;
      overflow-x: hidden;
   }

   .ads-nav {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -40px;

      img {
         width: 60%;
      }
   }
}

.icon-open {
   svg path {
      color: var(--text-color);
   }
}

@media (max-width: 740px) {
   .ads-nav {
      display: none !important;
   }
}
