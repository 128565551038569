.group-flags {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   padding: 20px;
}

button {
   &.btn-flag {
      border: none;
      background: none;

      img {
         width: 40px;
         height: 40px;
      }
   }

   &.btn-flag-menu {
      z-index: 9999;
      min-width: 0px !important;
      border: none;
      border-radius: 5px;
      padding: 2px;
      height: 47px;
      width: 47px;

      img {
         width: 30px;
         height: 30px;
      }

      transition: filter 400ms;
      &:hover {
         filter: brightness(0.8);
      }
   }
}

.modal-flags {
   border-radius: 10px;
   background: rgb(255 255 255 / 96%) !important;
}
