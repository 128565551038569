@font-face {
   font-family: 'Freshman';
   font-style: normal;
   font-weight: 400;
   src: local('Freshman'), url('https://fonts.cdnfonts.com/s/2128/Freshman.woff') format('woff');
}

.header {
   display: flex;
   align-items: center;
   height: 80px;
   width: 100%;
   background: var(--background-top);
   z-index: 2;
   gap: 20px;

   .logo {
      width: 100%;
      height: 19px;
      margin-left: 20px;
   }

   .btn-hamburguer {
      display: flex;
      align-items: center;
   }

   .ads {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      img {
         height: 56px;
      }
   }

   .group-wallet {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
   }
}
.txt-2910 {
   font-size: 21.79px;
   font-family: 'Bitter', sans-serif;
   color: rgba(255, 255, 255, 1);
   width: auto;
   height: 19px;
   word-wrap: break-word;
   margin: 0 0 0 40px;
}

.txt-642 {
   font-size: 28px;
   font-family: 'Bitter', sans-serif;

   color: rgba(255, 255, 255, 1);
   width: 29px;
   height: 29px;
   word-wrap: break-word;
   margin: auto;
}

.set-theme {
   border-radius: 5px;
   padding: 2px;
   height: 47px;
   width: 47px;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media screen and (max-width: 600px) {
   .header {
      justify-content: space-between;
   }

   .txt-2910 {
      font-size: 2vh;
      font-family: 'Bitter', sans-serif;

      color: rgba(255, 255, 255, 1);
      height: 2vh;
      word-wrap: break-word;
      margin: 0 0 0 20px;
   }

   .ads {
      display: none;
   }
}
