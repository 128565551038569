* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Russo One', sans-serif;
}

body {
   width: 100%;
   background: var(--background);
   overflow: hidden;

   &.dark {
      --color-primary: #252525;
      --background: #1d1d1d;
      --background-2: #282929;
      --background-3: #373838;
      --color-title: #38794f;
      --color-text-primary: #f3f3f3;
      --color-text-secondary: #7b898a;
      --color-title-section: #38794f;

      --white: #f3f3f3;
      --yellow: #d49c34;
      --blue: #3f8f96;
      --green: #38794f;
      --red-t: #ff000020;
      --red: #b32323;
      --button-blue: #3f8f96;
      --icedit: #f0ab02;

      --border: #f0ab0220;

      //---top
      --background-top: #15171c;
      --button-wallet: #252525;
      --button-theme: #d49c34;

      //---nav
      --background-nav: #252525;
      --background-footer: #202020;
      --background-sub-item: #252831;
      --text-color: #f3f3f3;
      --text-cs: #bd912c;
      --actived: #93822a;
      --actived-sub: #c6ae34;
      --hover: #252831;
   }
   &.light {
      --color-primary: #fff;
      --background: #f8f8f8;
      --background-2: #eaeaea;
      --background-3: #d8d8d8;
      --color-title: #45d2de;
      --color-title-section: #12434d;
      --color-text-primary: #12434d;
      --color-text-secondary: #276875;
      --white: #f3f3f3;
      --yellow: #f7c95f;
      --blue: #45d2de;
      --green: #41a062;
      --red-t: #ff000020;
      --red: #ff0000;

      --button-blue: #12434d;

      --border: #12434d20;
      --icedit: #12434d;
      //---top
      --background-top: #e1e1e1;
      --button-wallet: #262626;
      --button-theme: #12434d;

      //---nav
      --background-nav: #eaeaea;
      --background-footer: #eaeaea;
      --background-sub-item: #cfcfcf;
      --text-color: #12434d;
      --text-cs: #3569c5;
      --actived: #1c778b;
      --actived-sub: #2c96ad;
      --hover: #30a9c4;
   }
}
