.group-social {
   width: 100%;
   display: flex;
   padding: 10px;
   justify-content: center;

   .social {
      display: flex;
      gap: 10px;
      height: 132px;
      width: 95px;
   }
}
