@import '~balloon-css/src/balloon';

#content-wrapper {
   display: grid;
   grid-template-areas:
      'header header'
      'nav main'
      'footer main';
   grid-template-columns: 300px auto;
   grid-template-rows: 80px 1fr;
   height: 100vh;
   overflow: hidden;

   header {
      grid-area: header;
      z-index: 999;
   }

   nav {
      background: var(--background-nav);
      grid-area: nav;
      width: 300px;
      position: relative;
   }

   main {
      grid-area: main;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 41px;
   }

   footer {
      grid-area: footer;
      background: var(--background-footer);
   }
}

.ads {
   text-align: center;

   &.ads-to-mobile {
      display: none;
   }
}

.space-bottom {
   margin-bottom: 30px;
}

.container {
   width: 100%;
   margin: 0 auto;
   padding: 20px 30px;

   .section-title {
      font-size: 25px;
      color: var(--color-title);
      text-align: left;
      display: flex;
      font-style: normal;
      font-family: 'The Wild Breath of Zelda', sans-serif;
      font-weight: 700;
   }
}

.load {
   animation: load 1s linear infinite;
}

.coin1 {
   position: absolute;
   width: 14%;
   height: 21%;
   left: 80%;
   top: 20%;
   z-index: -1;
   opacity: 0.5;
}

.coin2 {
   position: absolute;
   width: 14%;
   height: 28%;
   left: 30%;
   top: 470px;
   z-index: -1;
   opacity: 0.5;
}

.coin3 {
   position: absolute;
   width: 14%;
   height: 28%;
   left: 32%;
   top: 105px;
   z-index: -1;
   opacity: 0.5;
}

.coin4 {
   position: absolute;
   width: 25%;
   height: 50%;
   left: 80%;
   top: 340px;
   z-index: -1;
   opacity: 0.5;
}

.elipse1 {
   position: fixed;
   width: 1799px;
   height: 1799px;
   left: -1200px;
   top: 75%;

   background: radial-gradient(50% 50% at 50% 50%, rgba(255, 198, 60, 0.57) 0%, rgba(255, 198, 59, 0) 100%);
}

.elipse2 {
   position: fixed;
   width: 917px;
   height: 917px;
   left: 1633px;
   top: 0px;

   background: radial-gradient(50% 50% at 50% 50%, rgba(69, 210, 222, 0.29) 0%, rgba(69, 210, 222, 0) 100%);
}

.ads250 {
   width: 250px;
   height: 250px;
   border: 0px;
   padding: 0;
   overflow: hidden;
   background-color: transparent;
}

.ads46860 {
   width: 468px;
   height: 60px;
   border: 0px;
   padding: 0;
   overflow: hidden;
   background-color: transparent;
}
.ads72890 {
   width: 728px;
   height: 90px;
   border: 0px;
   padding: 0;
   overflow: hidden;
   background-color: transparent;
}
.ads336280 {
   width: 336px;
   height: 280px;
   border: 0px;
   padding: 0;
   overflow: hidden;
   background-color: transparent;
}

@keyframes load {
   0% {
      transform: rotate(360deg);
   }

   100% {
      transform: rotate(0deg);
   }
}

.group-logo {
   display: flex;
   align-items: center;
   justify-content: space-evenly;

   .logo-text {
      h3 {
         color: var(--color-text-primary);
         font-weight: 500;
         width: 300px;
      }

      img.logo-Text {
         width: 270px;
         margin-bottom: 20px;
      }

      button {
         height: 50px;
         width: 200px;
         margin: auto;
         border: none;
         border-radius: 5px;
         color: #fff;
         cursor: pointer;
         margin-top: 30px;
         background: var(--blue);
         transition: filter 400ms;

         &:hover {
            filter: brightness(0.8);
         }
      }
   }

   img.logo-image {
      width: 329px;
      filter: drop-shadow(0px 0px 64px rgba(255, 198, 60, 0.35));
   }
}

hr {
   &.separator {
      height: 1px;
      border-top: 0;
      border-color: rgb(255, 255, 255, 0.15);
      margin: 40px 0;
   }
}

.holder-status {
   .group-holder-status {
      margin-top: 30px;
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      width: 100%;

      .holder-card {
         background: var(--background-nav);
         border: 1px solid var(--border);
         border-radius: 5px;
         width: 100%;
         padding: 15px;

         .holder-card-name {
            color: var(--color-title-section);
            font-family: 'The Wild Breath of Zelda', sans-serif;
            font-style: normal;
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: space-between;

            .icedit {
               height: 100%;
               background: transparent;
               border: none;

               i {
                  height: 10px;
                  width: 10px;
                  margin: auto;
                  color: var(--icedit);
               }
            }
         }

         .holder-amount {
            font-size: 20px;
            color: var(--color-text-primary);
            font-style: normal;
            font-family: 'The Wild Breath of Zelda', sans-serif;
            overflow-x: hidden;
            font-weight: 600;
         }

         .holder-value {
            color: var(--color-text-primary);
            font-family: 'The Wild Breath of Zelda', sans-serif;

            font-style: normal;
            font-size: 2vh;
            display: flex;
            align-items: center;
            overflow-x: hidden;
            margin-top: 10px;
         }
      }
   }
}

.value-empty {
   color: var(--color-text-primary);
}

.stake-card {
   background: var(--background-nav);
   border: 1px solid var(--border);
   border-radius: 5px;
   width: 100%;
   padding: 15px;
   display: flex;
   gap: 10px;
   color: var(--color-text-primary);

   .stake-card-content {
      display: grid;
      grid-template-columns: 75px auto auto;
      width: 100%;
      padding: auto;

      .icon-dalmata {
         height: 56px;
         width: 100%;
         margin: auto;
         background: url('https://pancake.kiemtienonline360.com/favicon.png');
         background-repeat: no-repeat;
         background-position: center;
         background-size: 53px;
         cursor: pointer;
      }

      p {
         font-size: '12px';
         text-align: 'left';
         height: 'min-conte;nt';
         padding: '2vh';

         span {
            font-size: 16px;
            color: var(--color-title);
            font-weight: bold;
         }
      }
   }

   .claim-dalmata {
      display: grid;
      grid-template-columns: 35px auto;
      height: 40px;
      width: 100%;
      background: var(--blue);
      align-items: center;
      color: #fff;
      border: none;
      border-radius: 8.32px;
      margin: auto;
      margin-left: 0;
      cursor: pointer;

      .icon-button-claim-dalmata {
         height: 35px;
         width: 35px;
         margin: auto;
         background: url('https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png');
         background-repeat: no-repeat;
         background-position: center;
         background-size: 30px;
         cursor: pointer;
      }

      p {
         margin: auto;
         font-size: 12px;
      }
   }
}

.stake-cardbutton {
   background: var(--background-nav);
   border: 1px solid var(--border);
   border-radius: 0px 0px 5px 5px;
   width: 100%;
   padding: 20px 10px;
}

.token-status {
   .group-token-status {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      width: 100%;
      padding: 20px;
      border-top: 1px solid var(--border);
      border-bottom: 1px solid var(--border);

      .token-card {
         border-right: 1px solid var(--border);
         width: 24%;
         padding: 10px;
         height: auto;

         &:last-child {
            border-right: none;
         }

         h2 {
            color: var(--color-title);
            font-family: 'The Wild Breath of Zelda', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
         }

         .token-amount {
            font-size: 18px;
            color: var(--color-text-primary);
            font-style: normal;
            font-weight: 700;
            font-family: 'The Wild Breath of Zelda', sans-serif;
            overflow-x: hidden;
         }

         .token-amount-sub {
            font-size: 12px;
            color: var(--text-cs);
            font-style: normal;
            font-weight: 700;
            font-family: 'The Wild Breath of Zelda', sans-serif;
            overflow-x: hidden;
            margin-top: 10px;
         }

         .token-value {
            color: var(--color-text-primary);
            font-family: 'The Wild Breath of Zelda', sans-serif;
            font-style: normal;
            font-size: 2vh;
            display: flex;
            align-items: center;
            overflow-x: hidden;
            margin-top: 20px;
         }
      }
   }
}

.wallet-fixed {
   position: fixed;
   top: 8px;
   right: 0px;
   z-index: 99999;
}

.MuiButtonBase-root {
   &.Mui-selected {
      color: var(--color-title-section) !important;
   }

   color: var(--color-text-primary) !important;
   font-family: 'The Wild Breath of Zelda', sans-serif !important;
}

.MuiBox-root {
   border: none !important;
   background: var(--background-2);
   padding: 0 5px 5px !important;
   border-radius: 5px;
}

.group-wallets {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   padding: 20px;
}

button {
   &.btn-wallet {
      border: none;
      border-radius: 10px;
      background: #e5e5e5;
   }
}

.modal-wallets {
   border-radius: 10px;
   background: rgb(255 255 255 / 96%) !important;
}

.group-dalmata {
   height: min-content;
   width: 100%;
   list-style-type: none;
   display: grid;
   padding: auto;
   margin-top: 30px;
}

.MuiTabs-root {
   margin-bottom: -26px;
   border-radius: 5px 5px 0 0;
}

.chart-margin {
   margin: 32px 2px 0;
}

.chart-col-12 {
   border: 1px solid rgba(255, 198, 60, 0.1254901961);
   border-radius: 5px;
   padding: 10px;
   background: var(--background);
}

//--Wallet----------------------------------------------------------
.connect {
   margin-right: 23px;
   box-sizing: border-box;
   border-radius: 5px;
   background: var(--green);
   width: 200px;
   height: 47px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: filter 400ms;

   &:hover {
      filter: brightness(0.9);
   }
}

.flex-row-vend {
   display: flex;
   flex-direction: row;
   align-items: flex-end;
}

.group-753 {
   height: 100%;
}
.wicon {
   height: 20px;
   width: 20px;
}
.group-576 {
   box-sizing: border-box;
   border-radius: 26.5px;
   height: 100%;
}

.group-3100 {
   padding: 10px;
   box-sizing: border-box;
   border-radius: 5px;
   background-color: var(--green);
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.txt-647 {
   font-size: 16px;
   font-family: 'Bitter', sans-serif;
   color: var(--button-wallet);
   text-transform: uppercase;
   margin: auto;
   margin-left: 0;
   overflow-x: hidden;
}

@media (max-width: 740px) {
   #content-wrapper {
      grid-template-columns: 0 auto;
   }

   .MuiTabs-flexContainer {
      overflow: auto;
   }

   .MuiTabs-indicator {
      display: none;
   }

   .ads {
      iframe {
         width: 100%;
      }

      &.ads-to-mobile {
         display: block;
         margin: 20px 0;
      }
   }
}

@media (max-width: 500px) {
   .wallet-fixed {
      position: fixed;
      top: 8px;
      right: 135px;
      z-index: 99999;
   }
}

@media (max-width: 380px) {
   .wallet-fixed {
      position: fixed;
      top: 8px;
      right: 125px;
      z-index: 99999;
   }
}

@media (max-width: 330px) {
   .wallet-fixed {
      position: fixed;
      top: 8px;
      right: 100px;
      z-index: 99999;
   }
}

@media (max-width: 280px) {
   .wallet-fixed {
      position: fixed;
      top: 8px;
      right: 80px;
      z-index: 99999;
   }
}

@media (max-width: 979px) {
   .coin1 {
      position: absolute;
      width: 42%;
      height: 21%;
      left: 50%;
      top: 20%;
      z-index: -1;
   }

   .connect {
      margin-right: 0;
   }

   .coin2 {
      position: absolute;
      width: 22%;
      height: 11%;
      left: 0%;
      top: 470px;
      z-index: -1;
   }

   .coin3 {
      position: absolute;
      width: 28%;
      height: 14%;
      left: 0%;
      top: 105px;
      z-index: -1;
   }

   .coin4 {
      position: absolute;
      width: 50%;
      height: 25%;
      left: 50%;
      top: 340px;
      z-index: -1;
   }

   .container {
      width: 100%;
      padding: 10px !important;

      .group-logo {
         flex-direction: column;

         img.logo-image {
            width: 250px;
            margin-bottom: 30px;
         }

         .logo-text {
            button {
               width: 100%;
            }
         }
      }

      .holder-status {
         .group-holder-status {
            margin-top: 50px;
            flex-direction: column;

            .holder-card {
               width: 100%;
            }
         }
      }

      .token-status {
         .group-token-status {
            .token-card {
               width: 100%;
               border-right: 0px;
               border-bottom: 1px solid var(--border);
               height: auto;
               padding: 10px 0;
            }
         }
      }
   }
}
