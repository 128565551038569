.group-superstaking {
   height: min-content;
   width: 100%;
   list-style-type: none;
   display: grid;
   padding: auto;
   margin-top: 30px;

   .stake-card-name {
      display: flex;
      width: 188px;
      justify-content: center;
      align-items: center;
      gap: 5px;

      img {
         width: 100px;
      }

      .stake-name {
         font-size: 12px;
         text-align: left;
         height: min-content;
         padding: 2vh;

         span {
            font-size: 16px;
            color: #fff;
            font-weight: bold;
         }
      }
   }

   .group-stake-value {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      align-items: baseline;
      align-content: space-around;

      .stakes-values {
         p {
            font-size: 12px;
            text-align: left;
            height: min-content;
            color: var(--color-title-section);

            &.p-values {
               font-size: 11px !important;
               font-weight: bold;
               text-align: left;
               height: min-content;
               color: var(--color-text-secondary);
            }
         }

         span {
            font-size: 15px;
            text-align: left;
            height: min-content;
         }
      }
   }
}

.stake-cardbutton {
   display: grid;
   color: rgb(255, 255, 255);
   grid-template-columns: repeat(auto-fit, minmax(22vh, 1fr));
   gap: 5px;
   height: min-content;
   width: 80% !important;
   margin: 0px auto;

   .group-input {
      display: flex;
      width: 90%;
      max-width: 400px;
      margin: auto;

      input {
         width: 100%;
         height: 40px;
         margin: auto 0px auto auto;
         border-radius: 9.04px;
         border: none;
         outline: none;
         text-align: right;
         color: rgb(153, 153, 153);
         padding: 0 5px;
         font-size: 12px;
      }

      button {
         display: grid;
         grid-template-columns: 35px auto;
         height: 40px;
         width: 100%;
         background: #2aa1ab;
         transition: filter 400ms;
         align-items: center;
         color: rgb(255, 255, 255);
         border: none;
         border-radius: 8.32px;
         margin: auto auto auto 0px;
         padding: 0 10px;

         p {
            margin: auto;
            font-size: 12px;
         }
      }
   }
}

@media (max-width: 740px) {
   .stake-card {
      flex-direction: column;

      .stakes-values {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         border-bottom: 1px solid;
         padding: 20px;

         &:last-child {
            border: none;
         }
      }
   }
   .stake-cardbutton {
      width: 100% !important;
   }

   .group-superstaking {
      .group-stake-value {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-around;
         width: 100%;
         flex-direction: column;
         gap: 20px;
      }
   }
}
