@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
}

.inner_body {
   padding: 3rem 3rem;
}

.inner_body .cards_wrapper {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-gap: 1rem;
}

.inner_body .cards_wrapper > div {
   padding: 1rem;
   height: 100px;
   display: flex;
   align-items: center;
   border-radius: 5px;
   border: 1px solid var(--border);
   background: var(--background-2);
}

.inner_body .cards_wrapper > div > img {
   margin-right: 20px;
   width: 50px;
}

.inner_body .cards_wrapper > div p {
   color: var(--color-title-section);
   font-family: 'The Wild Breath of Zelda', sans-serif;
   font-size: 16px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
}

.inner_body .cards_wrapper > div h1 {
   color: var(--color-text-primary);
   font-size: 18px;
   font-weight: 400;
   line-height: 31px;
   letter-spacing: 0em;
   text-align: left;
}

.inner_body .cards_wrapper > div span {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--color-text-primary);
}

.grid_area {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-gap: 1rem;
   margin-top: 2rem;
}

.grid_arean {
   grid-template-columns: auto;
}

.options_wrapper_area > *:first-child {
   grid-column: span 2;
}

.options_wrapper_area .options_wrapper {
   background-color: var(--background-2);
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   border-radius: 5px;
}

.options_wrapper_area .options_wrapper .top_area {
   display: flex;
}

.options_wrapper_area .options_wrapper .top_area img {
   cursor: pointer;
}

.options_wrapper_area .options_wrapper .top_area > *:first-child {
   border-top-left-radius: 5px;
}

.options_wrapper_area .options_wrapper .top_area > *:last-child {
   border-top-right-radius: 5px;
}

.options_wrapper_area .options_wrapper .top_area button {
   flex: 1;
   cursor: pointer;
   border: none;
   outline: none;

   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   letter-spacing: 0em;
   text-align: center;
   color: var(--color-title-section);
   background: var(--background-2);
   height: 47px;
}

.options_wrapper_area .options_wrapper .top_area button.active {
   background: var(--green);
   color: #061617;
}

.step {
   padding: 2rem 1rem;
}

.step .input_wrapper {
   display: flex;
   align-items: center;
   gap: 10px;
   width: 100%;
}

.step .input_wrapper img {
   height: 50px;
   width: 50px;
   margin-right: 5px;
}

.step .input_wrapper p {
   width: min-content;
   font-family: 'The Wild Breath of Zelda';
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   margin-right: 5px;
   margin-left: 5px;
   color: var(--color-text-primary);
}

.step .input_wrapper p span {
   font-size: 15px;
   color: var(--color-text-primary);
}

.step .input_wrapper input {
   background: var(--background);
   border: none;
   border-radius: 5px;
   color: #7b898a;
   height: 100%;
   width: 100%;
   flex: 1;
   padding: 0rem 1rem;
   outline: none;
   font-family: 'The Wild Breath of Zelda';
   font-size: 14px;
   font-weight: 400;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
}

.step .input_wrapper div {
   display: grid;
   grid-template-columns: auto 1fr 1fr;
   background: var(--background);
   border: 1px solid var(--border);
   border-radius: 5px;
   color: #7b898a;
   height: 53px;
   width: 100%;
   outline: none;
   font-family: 'The Wild Breath of Zelda';
   font-size: 14px;
   font-weight: 400;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
}

.step .input_wrapper div span {
   font-size: 14px;
   color: var(--green);
   margin: auto;
   height: min-content;
}

.step .input_wrapper button {
   height: 40px;
   width: 100%;
   min-width: 150px;
   border-radius: 5px;
   text-align: center;
   color: var(--white);
   font-size: 13px;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0em;
   margin-left: 0.2rem;
   cursor: pointer;
}

.stakebutton button {
   background: var(--green);
   border: 1px solid var(--green);
}

.unstakebutton button {
   background: var(--red-t);
   border: 1px solid var(--red);
}

.claimbutton button {
   background: var(--green);
   border: 1px solid var(--green);
   color: var(--color-text-primary) !important;
}

.step .tags_wrapper {
   display: flex;
   align-items: center;
   margin: auto;
   width: 100%;

   margin-top: 2vh;
}

.step .tags_wrapper button {
   min-height: 50px;
   width: 100%;
   height: 100%;
   border-radius: 5px;
   font-family: 'The Wild Breath of Zelda';
   font-size: 14px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: center;
   border: none;
   outline: none;
   cursor: pointer;
   border: 1px solid var(--button-blue);
   color: var(--button-blue);
   background: transparent;
}

.step .tags_wrapper button.active {
   background: var(--button-blue);
   color: var(--white);
}

.step .tags_wrapper > * + * {
   margin-left: 10px;
}

.step .step_card {
   display: grid;
   align-items: center;
}

.step .step_card img {
   margin-right: 1rem;
}

.step .step_card h1 {
   color: var(--color-text-primary);
   font-family: 'The Wild Breath of Zelda';
   font-size: 14px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
}

.step .step_card h1 span {
   font-weight: bold;
   color: var(--green);
   font-size: 16px;
}

.step .step_card p {
   color: var(--color-text-secondary);
   font-family: 'The Wild Breath of Zelda';
   font-size: 13px;
   font-weight: 400;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
   margin-top: 5px;
}

.descr {
   color: var(--color-text-primary);
   font-size: 14px;
   margin-top: 40px;
}

.step .step_card p span {
   font-weight: 500;
   color: var(--green);
}

.step .step_card + p {
   margin-top: 1.5rem;

   font-family: 'The Wild Breath of Zelda';
   font-style: normal;
   font-weight: 500;
   font-size: 16px;

   color: var(--color-text-primary);
}

.step span {
   margin-top: 1.5rem;

   font-family: 'The Wild Breath of Zelda';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;

   color: var(--color-text-primary);
}

.pres {
   display: flex;
   gap: 10px;

   img {
      width: 50px;
   }
}

.presentation {
   width: 100%;
}

.pro {
   display: grid;
   align-items: center;
   width: 100%;
   margin-top: 2rem;
   grid-template-columns: auto auto;
}

.wallet {
   background: var(--background-2);
   border-radius: 5px;
}

.wallet .top_area {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 1rem;
}

.wallet .top_area img {
   cursor: pointer;
}

@keyframes loading {
   0% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(360deg);
   }
}

.wallet .top_area h1 {
   color: var(--color-title-section);

   font-size: 20px;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: 0em;
   text-align: left;
}

.list_wrapper .box {
   display: flex;
   align-items: center;
   gap: 20px;

   img {
      width: 50px;
   }
}

.list_wrapper {
   height: 300px;
   padding: 1rem;
}

.list_wrapper::-webkit-scrollbar {
   display: none;
}

.list_wrapper > * + * {
   margin-top: 1rem;
}

.list_wrapper .box {
   height: 84px;
   border-radius: 5px;
   background-color: var(--background-3);
   padding: 0rem 10px;
}

.list_wrapper .box img {
   margin-right: 10px;
}

.list_wrapper .box h1 {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--color-text-secondary);
}

.list_wrapper .box p {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 500;
   line-height: 19px;
   letter-spacing: 0em;

   color: var(--color-text-primary);
}

.list_wrapper .box p span {
   font-size: 17px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
}

.grid_area .history_wrapper {
   grid-column: span 2;
   background-color: var(--background-2);
   border-radius: 5px;
}

.grid_area .history_wrapper .top_area {
   padding: 2rem 2rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.grid_area .history_wrapper .top_area h1 {
   font-size: 20px;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: 0em;
   color: var(--color-title-section);
}

.grid_area .history_wrapper .top_area .button_wrapper {
   display: flex;
   align-items: center;
   border: 0.5px solid #38cedc;
   padding: 5px;
   gap: 5px;
   border-radius: 5px;
}

.grid_area .history_wrapper .top_area .button_wrapper button {
   height: 35px;
   width: 87px;
   cursor: pointer;
   border: none;
   outline: none;
   border-radius: 5px;
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 500;
   line-height: 19px;
   letter-spacing: 0em;
   color: var(--button-blue);
   border: none;
   background: transparent;
   text-align: center;
}

.grid_area .history_wrapper .top_area .button_wrapper button.active {
   background: #38cedc;
   color: #061617;
}

.grid_area .table_area .table_head,
.table_body .row {
   display: grid;
   padding: 0rem 2rem;
   padding-bottom: 1rem;
   color: var(--color-title-section);
   grid-template-columns: 20% 20% 20% 20% 20%;
}

.grid_area .table_area .table_head p,
.table_body .row p {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 400;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   display: flex;
   align-items: center;
}

.over {
   overflow: auto;
}

.grid_area .table_area .table_head p input,
.table_body .row p input {
   margin-right: 5px;
}

.table_body .row button {
   height: 29px;
   width: 90%;
   border: none;
   color: #fff;
   border-radius: 5px;
   background: #38c368;
}

.table_body .row p img {
   height: 20px;
   width: 20px;
}

.table_body .row p a {
   color: #fff;
   overflow: hidden;

   cursor: pointer;
}

.listtable {
   overflow-y: auto;
   overflow-x: hidden;
   max-height: 300px;
}

.chart {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 90%;
   width: 60vh;
   min-height: 50vh;
   min-width: 100%;
}

.grid_area .chart_area {
   background: var(--background-2);
   padding: 0.5rem;

   border-radius: 5px;
}

.grid_area .chart_area h1 {
   font-size: 17px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--color-title-section);
}

.grid_area .chart_area p {
   font-family: 'The Wild Breath of Zelda';
   font-size: 14px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--color-text-primary);
   margin-top: 5px;
}

.row_wrapper_second {
   display: grid;
   align-items: center;
   justify-content: space-between;
   grid-template-columns: repeat(auto-fit, minmax(25vh, 1fr));

   padding: 5px 0;

   &:last-child {
      border: none !important;
   }
}

.row_wrapper_second > div {
   display: flex;
   align-items: center;
   width: 100%;
   min-width: 80px;
}

.row_wrapper_second > div > img {
   margin-right: 10px;
   height: 40px;
   width: 40px;
}

.row_wrapper_second > div h1 {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
   color: var(--color-title-section);
   width: 100%;
}

.row_wrapper_second > div h1 span {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: center;
   color: var(--color-text-primary);
}

.row_wrapper_second > div p {
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: center;
   color: #7b898a;
}

.options_wrapper {
   position: relative;
}

.new_second_input {
   width: 100%;
   margin-bottom: 20px;
}

.row_button {
   height: 31px;
   width: 90%;
   min-width: 86px;
   margin: auto;

   border-radius: 5px;
   font-family: 'The Wild Breath of Zelda';
   font-size: 16px;
   font-weight: 500;
   line-height: 21px;
   letter-spacing: 0em;
   text-align: center;
   border: none;
   outline: none;
   cursor: default;
   border: 1px solid var(--button-blue);
   color: var(--button-blue);
   background: transparent;
}

@media screen and (max-width: 600px) {
   .inner_body {
      padding: 10px;

      .cards_wrapper {
         display: flex !important;
         flex-direction: column;
      }

      .grid_area {
         display: flex;
         flex-direction: column;
      }

      .step {
         .input_wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;

            button {
               margin-left: 0;
               width: 100%;
            }

            img {
               height: 50px;
               width: 50px;
            }
         }
      }

      .row_wrapper_second {
         gap: 10px;
         padding: 0 0 20px;
      }

      .pres {
         display: flex;
         gap: 5px;
         flex-direction: column;
         align-items: center;
      }

      .step .input_wrapper p {
         width: auto;
         font-family: 'The Wild Breath of Zelda';
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         margin-right: 5px;
         margin-left: 5px;
         color: var(--color-text-primary);
      }

      .step .input_wrapper p span {
         font-size: 16px;
         color: var(--color-text-primary);
      }

      .pro {
         display: grid;
         align-items: center;
         margin-top: 2rem;
         grid-template-columns: auto;
      }

      .table_body .row {
         display: grid;
         padding: 0rem 2rem;
         padding-bottom: 1rem;
         color: var(--color-text-primary);
         grid-template-columns: repeat(5, 100px);
      }

      .history_wrapper {
         overflow: auto;

         .top_area {
            align-items: initial;
            flex-direction: column;
            gap: 20px;
         }
      }

      .chart {
         display: flex;
         align-items: center;
         justify-content: center;

         min-height: 50vh;
         height: 50vh;
         width: 99%;
      }
   }
}
