/* Track */
::-webkit-scrollbar-track {
   border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: rgb(247, 246, 246);
   border-radius: 10px;
}
