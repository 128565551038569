.container-loading {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 80px;

   .loading-icon {
      color: var(--blue);
      font-size: 2rem;
      animation: animate 2s infinite;
   }
}

@keyframes animate {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(720deg);
   }
}
