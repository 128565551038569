.chart-group {
   display: flex;
   margin-top: 30px;
   width: 100%;
   gap: 10px;
   margin-bottom: 10px;

   .card-chart {
      width: 50%;
      margin: 2px;
      border: 1px solid rgba(255, 198, 60, 0.1254901961);
      border-radius: 5px;
      padding: 10px;
      background: var(--background);
   }
}

text {
   font-weight: 500 !important;
}

@media (max-width: 979px) {
   .chart-group {
      flex-direction: column;

      .card-chart {
         width: 100%;
         border: 1px solid #6b6b6b20;
      }
   }
}
